<template>
  <div class="show_chanel">
    <div class="search_input">
      <c-input-group>
        <c-input-left-element>
          <i class="fas fa-search"></i>
        </c-input-left-element>
        <c-input
          placeholder="Search channels..."
          borderWidth="0"
          borderRadius="20"
          size="lg"
          variant="filled"
          type="text"
          v-model="searchValue"
        />
      </c-input-group>
    </div>

    <c-box mt="5" p="5">
      <div v-for="(ch, index) in searchChannels" :key="index">
        <c-box
          d="flex"
          alignItems="center"
          justify-content="space-between"
          class="user_container"
        >
          <div class="user">
            <span class="first_letter">{{ getFirstLetter(ch.name) }}</span>
            <!-- <img src="@/assets/img/eventImg.jpeg" alt="" /> -->
            <div class="details">
              <h3>{{ ch.name }}</h3>
              <small
                ><c-badge mx="2" variant-color="green">12</c-badge
                >participants</small
              >
            </div>
          </div>

          <c-button
            size="sm"
            variant-color="vc-orange"
            variant="link"
            @click="joinChannel(ch)"
            :is-loading="btnLoading"
          >
            {{ followed ? 'Joined' : 'Join' }}
          </c-button>
        </c-box>
      </div>
    </c-box>

    <div class="msgBtn">
      <p>Suggested Users</p>
    </div>

    <div class="mx-5">
      <UsersLists
        type="newUsers"
        :searchable="true"
        heightId="fixedHeight"
        :isSendMessage="true"
        @messageUser="sendQuickMessage"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { getOpenChannels, joinAChannel } from '@/services/messages.js';
import messagingMixin from '@/mixins/messaging.js';
import UsersLists from './components/UsersLists.vue';

export default {
  name: 'StartConversation',
  mixins: [messagingMixin],
  components: {
    UsersLists,
  },
  data() {
    return {
      channels: [],
      searchValue: '',
      followed: false,
      btnLoading: false,
    };
  },
  created() {
    this.openChannelList();
  },
  computed: {
    searchChannels() {
      if (this.searchValue) {
        return this.channels.filter((data) => {
          return Object.keys(data).some((key) => {
            return String(data[key])
              .toLowerCase()
              .includes(this.searchValue.toLowerCase());
          });
        });
      } else {
        return this.channels;
      }
    },
  },
  methods: {
    async openChannelList() {
      const loggedInUser = store.state.auth.user.id;
      try {
        const response = await getOpenChannels(loggedInUser, 50);
        this.channels = response.data.chat_channel;
      } catch (error) {
        console.log(error);
      }
    },
    getFirstLetter(name) {
      if (name) {
        return name.charAt(0).toUpperCase();
      }
    },
    async joinChannel(chan) {
      this.btnLoading = true;
      const loggedInUser = store.state.auth.user.id;
      const payload = {
        channel_id: chan.id,
        participant_id: loggedInUser,
      };

      try {
        await joinAChannel(payload);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
        this.$emit('close');
      } catch (error) {
        this.btnLoading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user_container {
  padding: 5px;

  &:hover {
    background: #e4eaf5;
    border-radius: 3px;
  }
  .user {
    display: flex;

    margin-bottom: 3px;
    cursor: pointer;
    padding: 8px 5px;

    .first_letter {
      font-size: 20px;
      font-weight: 600;
      background-color: #e7592e;
      min-width: 50px;
      min-height: 45px;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    img {
      margin-right: 10px;
      width: 45px;
      height: 45px;
      object-fit: fill;
    }

    .details {
      h3 {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-primary);
      }

      p {
        margin-top: 3px;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: var(--color-primary);
      }
    }
  }
}

.msgBtn {
  display: flex;
  align-items: center;
  margin-top: 15px;
  background: #d5e0f1;
  padding: 5px 20px;

  p {
    color: #6b6b6b;
    font-size: 15px;
    cursor: pointer;
  }
}
</style>
